import { Subject } from 'rxjs'
import { BrowserIOSubject } from '~/utils/BrowserIOSubject'
import isBrowser from '~/utils/is-browser'
import { COLOR_THEMES, defaultColorTheme, defaultFontTheme, FONT_THEMES } from '~/variables'
import AppService from './app.service'

// function setCssVariable(name: string, value: string) {
//   console.log('setCssVariable', name, value)
//   if (!name || !value) return
//   document.documentElement.style.setProperty(
//     `--${name}`,
//     value
//   )
// }

// function useCssVariableRx(
//   name: string,
//   value$: ValueObservable<number> | ValueObservable<string>
// ) {
//   if (value$.value === undefined || value$.value === null) return
//   if (typeof value$.value === 'string') {
//     const [value] = useObservable<string>(value$ as ValueObservable<string>, value$.value)
//     useEffect(() => {
//       if (!name || value === undefined) return
//       setCssVariable(name, value)
//     }, [value])
//   } else {
//     const [value] = useObservable<number>(value$ as ValueObservable<number>, value$.value)
//     useEffect(() => {
//       if (!name || value === undefined) return
//       setCssVariable(name, value.toString())
//     }, [value])
//   }

// }
// useCssVariableRx('background', tl.background$)
// useCssVariableRx('textColor', tl.textColor$)

// useCssVariableRx('bodyFamily', tl.bodyFamily$)
// useCssVariableRx('headerFamily', tl.headerFamily$)
// useCssVariableRx('bodyWeight', tl.bodyWeight$)
// useCssVariableRx('boldWeight', tl.boldWeight$)
// useCssVariableRx('headerWeight', tl.headerWeight$)
// useCssVariableRx('bodySize', tl.bodySizeRel$)

class CssVariableStringSubject extends BrowserIOSubject<string> {
  static getter = (key: string, fallbackValue: string) => {
    if (!isBrowser()) return fallbackValue
    if (!key) return fallbackValue
    const rawValue = document.documentElement.style.getPropertyValue(`--${key}`)
    if (!rawValue) return fallbackValue
    return rawValue
  }

  static setter = (key: string, value: string) => {
    if (!isBrowser()) return
    if (!key) return
    document.documentElement.style.setProperty(`--${key}`, value)
  }

  constructor(key: string, fallbackValue: string) {
    const { getter, setter } = CssVariableStringSubject
    super(getter, setter, key, fallbackValue)
  }
}

class CssVariableNumberSubject extends BrowserIOSubject<number> {
  static getter = (key: string, fallbackValue: number) => {
    if (!isBrowser()) return fallbackValue
    if (!key) return fallbackValue
    const rawValue = document.documentElement.style.getPropertyValue(`--${key}`)
    if (!rawValue || rawValue.length === 0) return fallbackValue
    try {
      return parseFloat(rawValue)
    } catch {
      return fallbackValue
    }
  }

  static setter = (key: string, value: number) => {
    if (!isBrowser()) return
    if (!key) return
    document.documentElement.style.setProperty(`--${key}`, value.toString())
  }

  constructor(key: string, fallbackValue: number) {
    const { getter, setter } = CssVariableNumberSubject
    super(getter, setter, key, fallbackValue)
  }
}

export class CssVariableService {
  background$ = new CssVariableStringSubject(
    'background',
    COLOR_THEMES[defaultColorTheme].background
  )
  textColor$ = new CssVariableStringSubject(
    'textColor',
    COLOR_THEMES[defaultColorTheme].textColor
  )

  primaryColor$ = new CssVariableStringSubject(
    'primaryColor',
    COLOR_THEMES[defaultColorTheme].primaryColor
  )

  bodyFamily$ = new CssVariableStringSubject(
    'bodyFamily',
    FONT_THEMES[defaultFontTheme].bodyFamily
  )
  headerFamily$ = new CssVariableStringSubject(
    'headerFamily',
    FONT_THEMES[defaultFontTheme].headerFamily
  )
  bodyWeight$ = new CssVariableNumberSubject(
    'bodyWeight',
    FONT_THEMES[defaultFontTheme].bodyWeight
  )
  boldWeight$ = new CssVariableNumberSubject(
    'boldWeight',
    FONT_THEMES[defaultFontTheme].boldWeight
  )
  headerWeight$ = new CssVariableNumberSubject(
    'headerWeight',
    FONT_THEMES[defaultFontTheme].headerWeight
  )
  bodySizeRel$ = new CssVariableStringSubject(
    'bodySize',
    `${(FONT_THEMES[defaultFontTheme].bodySize / 16) * 100}%`
  )

  constructor(private readonly app: AppService) {}
}
