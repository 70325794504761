import { BehaviorSubject, Subject } from 'rxjs'
import isBrowser from '~/utils/is-browser'
import { BrowserIOReadonlySubject, BrowserIOSubject } from './BrowserIOSubject'

export class LocalStorageReadonlyNumberSubject extends BrowserIOReadonlySubject<number> {
  static getter(key: string, fallbackValue?: number) {
    if (!isBrowser()) return fallbackValue
    const rawValue = window.localStorage.getItem(key)
    if (!rawValue || typeof rawValue !== 'string' || rawValue.length === 0) return fallbackValue
    try {
      return parseFloat(rawValue)
    } catch {
      return fallbackValue
    }
  }

  constructor(public readonly key: string, fallbackValue: number) {
    const { getter } = LocalStorageReadonlyNumberSubject
    super(getter, key, fallbackValue)
  }
}

export class LocalStorageNumberSubject extends BrowserIOSubject<number> {
  static setter(key: string, value: number) {
    if (!isBrowser()) return
    window.localStorage.setItem(key, value.toString())
  }

  constructor(public readonly key: string, fallbackValue: number) {
    const { getter } = LocalStorageReadonlyNumberSubject
    const { setter } = LocalStorageNumberSubject
    super(getter, setter, key, fallbackValue)
  }
}

export class LocalStorageReadonlyStringSubject extends BrowserIOReadonlySubject<string> {
  static getter(key: string, fallbackValue?: string) {
    if (!isBrowser()) return fallbackValue
    const rawValue = window.localStorage.getItem(key)
    if (!rawValue || typeof rawValue !== 'string') return fallbackValue
    return rawValue
  }

  constructor(public readonly key: string, fallbackValue: string) {
    const { getter } = LocalStorageReadonlyStringSubject
    super(getter, key, fallbackValue)
  }
}

export class LocalStorageStringSubject extends BrowserIOSubject<string> {
  static setter(key: string, value: string) {
    if (!isBrowser()) return
    window.localStorage.setItem(key, value)
  }

  constructor(public readonly key: string, fallbackValue: string) {
    const { getter } = LocalStorageReadonlyStringSubject
    const { setter } = LocalStorageStringSubject
    super(getter, setter, key, fallbackValue)
  }
}

// class LocalStorageSubject<T> extends BehaviorSubject<T> {
//   getItem = () => this.getter(this.key, this.value)
//   setItem = (value: T) => this.setter(this.key, value)

//   reload() {
//     this.next(this.getItem())
//   }

//   constructor(
//     private readonly getter: (key: string, fallbackValue: T) => T,
//     private readonly setter: (key: string, value: T) => void,
//     public readonly key: string,
//     fallbackValue: T
//   ) {
//     super(getter(key, fallbackValue))
//     this.subscribe(this.setItem)
//   }
// }
