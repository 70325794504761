import { BehaviorSubject } from 'rxjs'

export class BrowserIOReadonlySubject<T> extends BehaviorSubject<T> {
  getItem = () => this.getter(this.key, this.value)

  reload() {
    this.next(this.getItem())
  }

  constructor(
    private readonly getter: (key: string, fallbackValue: T) => T,
    public readonly key: string,
    fallbackValue: T
  ) {
    super(getter(key, fallbackValue))
  }
}

export class BrowserIOSubject<T> extends BrowserIOReadonlySubject<T> {
  setItem = (value: T) => this.setter(this.key, value)

  constructor(
    getter: (key: string, fallbackValue: T) => T,
    private readonly setter: (key: string, value: T) => void,
    public readonly key: string,
    fallbackValue: T
  ) {
    super(getter, key, fallbackValue)
    this.subscribe(this.setItem)
  }
}
