import { AppService } from '~/services/app.service'
import { createProviderWithInit } from '~/utils/provider-factory'

const tuple = createProviderWithInit<AppService>((app: AppService) => {
  return () => {
    app.init()
  }
})

export const AppProvider = tuple[0]
export const useApp = tuple[1]

export default AppProvider
