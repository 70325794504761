import { auditTime, BehaviorSubject, distinctUntilChanged, fromEvent, map, tap } from 'rxjs'
import isBrowser from '~/utils/is-browser'
import ValueSubject, { ValueObservable } from '~/utils/ValueSubject'
import { baseWidth } from '~/variables'
import { CssVariableService } from './cssvariable.service'
import LocalStorageService from './localstorage.service'
import TextlookService from './textlook.service'

type AppEnv = typeof process.env

const MAX_LEVEL = 2

export class AppService {
  public pageWidth$ = new BehaviorSubject<number>(isBrowser() ? window.innerWidth : baseWidth)
  public drawer$ = new BehaviorSubject<boolean>(true)
  public pageUrl$ = new ValueSubject<string>()
  public history: string[] = []
  public isMainpage$ = new ValueObservable<boolean>(
    this.pageUrl$.pipe(map(urlPath => urlPath === '/'))
  )
  public uiScale$ = new ValueObservable<number>(
    this.pageWidth$.pipe(
      map(w => {
        console.log(w, w * 0.025)
        const result = Math.min(Math.max(18, w * 0.025), 28)
        console.log(result)
        return result
      })
    )
  )

  public textlook = new TextlookService(this)
  public localStorage = new LocalStorageService(this)
  public cssVariable = new CssVariableService(this)

  private _constructor() {
    console.log('app service _constructor')
    fromEvent(window, 'resize')
      .pipe(
        auditTime(100),
        map(ev => (ev.target as Window).innerWidth),
        distinctUntilChanged()
      )
      .subscribe(this.pageWidth$)
  }

  public init() {
    if (!isBrowser()) return
    this.pageWidth$.next(window.innerWidth)
    console.log('app context init', window.innerWidth, this.pageWidth$.value)
  }

  constructor(public env: AppEnv = process.env) {
    console.log('app service constructor')
    if (!isBrowser()) return
    fromEvent(window, 'resize')
      .pipe(
        auditTime(100),
        map(ev => (ev.target as Window).innerWidth),
        distinctUntilChanged()
      )
      .subscribe(this.pageWidth$)

    //sync
    const tl = this.textlook
    const ls = this.localStorage
    const cv = this.cssVariable

    ls.fontScaleInitial$.subscribe(tl.fontScale$)
    ls.paddingScaleInitial$.subscribe(tl.paddingScale$)
    ls.lineHeightInitial$.subscribe(tl.lineHeight$)

    tl.fontScale$.subscribe(ls.fontScale$)
    tl.paddingScale$.subscribe(ls.paddingScale$)
    tl.lineHeight$.subscribe(ls.lineHeight$)

    tl.background$.subscribe(cv.background$)
    tl.textColor$.subscribe(cv.textColor$)
    tl.primaryColor$.subscribe(cv.primaryColor$)
    tl.bodyFamily$.subscribe(cv.bodyFamily$)
    tl.headerFamily$.subscribe(cv.headerFamily$)
    tl.bodyWeight$.subscribe(cv.bodyWeight$)
    tl.boldWeight$.subscribe(cv.boldWeight$)
    tl.headerWeight$.subscribe(cv.headerWeight$)
    tl.bodySizeRel$.subscribe(cv.bodySizeRel$)

    this.pageUrl$.subscribe(urlPath => this.history.push(urlPath))
    this.pageUrl$.subscribe(urlPath => console.log(this.history))
  }
}

export default AppService
