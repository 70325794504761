import { BehaviorSubject, Subject } from 'rxjs'
import isBrowser from '~/utils/is-browser'
import AppService from './app.service'
import {
  LocalStorageReadonlyNumberSubject,
  LocalStorageNumberSubject
} from '~/utils/LocalStorageSubject'

export class LocalStorageService {
  fontScaleInitial$ = new LocalStorageReadonlyNumberSubject('font-scale', 0)
  paddingScaleInitial$ = new LocalStorageReadonlyNumberSubject('padding-scale', 10)
  lineHeightInitial$ = new LocalStorageReadonlyNumberSubject('line-height', 1.5)

  fontScale$ = new LocalStorageNumberSubject('font-scale', this.fontScaleInitial$.value)
  paddingScale$ = new LocalStorageNumberSubject(
    'padding-scale',
    this.paddingScaleInitial$.value
  )
  lineHeight$ = new LocalStorageNumberSubject('line-height', this.lineHeightInitial$.value)

  reload() {
    this.fontScaleInitial$.reload()
    this.paddingScaleInitial$.reload()
    this.lineHeightInitial$.reload()
  }

  constructor(private readonly app: AppService) {}
}

export default LocalStorageService
