import { BehaviorSubject, combineLatest, fromEvent, Observable, of } from 'rxjs'
import { tap, auditTime, map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators'
import {
  baseWidth,
  COLOR_THEMES,
  defaultColorTheme,
  defaultFontTheme,
  FontTheme,
  FONT_THEMES
} from '~/variables'
import type { ColorTheme } from '~/variables'
import KeySubject from '~/utils/KeySubject'
import { ValueObservable } from '~/utils/ValueSubject'
import RangeSubject from '~/utils/RangeSubject'
import isBrowser from '~/utils/is-browser'
import AppService from './app.service'

export default class TextlookService {
  pageWidth$ = new BehaviorSubject<number>(isBrowser() ? window.innerWidth : baseWidth)

  colorTheme$ = new KeySubject<ColorTheme>(defaultColorTheme, COLOR_THEMES)
  background$ = new ValueObservable<string>(
    this.colorTheme$.value$.pipe(map(v => v.background))
  )
  textColor$ = new ValueObservable<string>(this.colorTheme$.value$.pipe(map(v => v.textColor)))
  primaryColor$ = new ValueObservable<string>(
    this.colorTheme$.value$.pipe(map(v => v.primaryColor))
  )

  fontTheme$ = new KeySubject<FontTheme>(defaultFontTheme, FONT_THEMES)
  bodyFamily$ = new ValueObservable<string>(this.fontTheme$.value$.pipe(map(v => v.bodyFamily)))
  headerFamily$ = new ValueObservable<string>(
    this.fontTheme$.value$.pipe(map(v => v.headerFamily))
  )
  bodyWeight$ = new ValueObservable<number>(this.fontTheme$.value$.pipe(map(v => v.bodyWeight)))
  boldWeight$ = new ValueObservable<number>(this.fontTheme$.value$.pipe(map(v => v.boldWeight)))
  headerWeight$ = new ValueObservable<number>(
    this.fontTheme$.value$.pipe(map(v => v.headerWeight))
  )
  bodySize$ = new ValueObservable<number>(this.fontTheme$.value$.pipe(map(v => v.bodySize)))

  fontProportion$ = new ValueObservable<number>(
    this.fontTheme$.value$.pipe(map(v => v.fontProportion))
  )

  bodySizeRel$ = new ValueObservable<string>(
    this.bodySize$.pipe(map(v => `${(v / 16) * 100}%`))
  )

  lineHeight$ = new RangeSubject(1.5, 0.9, 3, 0.1)

  fontScale$ = new RangeSubject(0, -50, 50, 5)
  charPerRow$ = new ValueObservable<number>(
    combineLatest([this.pageWidth$, this.fontScale$]).pipe(
      distinctUntilChanged(),
      map(([w, s]) => {
        const cw = w < 400 ? 40 : w < 600 ? Math.round(w / 10) : 60
        const cs = Math.round(cw + (s / 100) * cw)
        return cs
      })
    )
  )

  paddingScale$ = new RangeSubject(10, 0, 33, 1)
  fontSizeRx$ = new ValueObservable<number>(
    combineLatest([this.charPerRow$, this.paddingScale$, this.fontProportion$]).pipe(
      distinctUntilChanged(),
      map(([c, s, p]) => {
        return Math.round(((100 - s * 2) / (c / p)) * 100) / 100
      })
    )
  )

  constructor(private readonly app: AppService) {
    this.colorTheme$.next(defaultColorTheme)
    this.fontTheme$.next(defaultFontTheme)
    this.app.pageWidth$.subscribe(this.pageWidth$)
  }
}
