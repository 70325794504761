import { PageProps } from 'gatsby'
import React from 'react'
import PageProvider from '~/providers/PageProvider'
import PageService, { PostListService, PostService } from '~/services/page.service'
import { appService } from './AppRoot'
import AppService from './services/app.service'
import { PageContext } from './types'

const cretePageService = (appService: AppService, props: PageProps) => {
  const pc = props.pageContext as PageContext
  switch (pc.type) {
    case 'post':
      return new PostService(appService, props)
    case 'post-list':
      return new PostListService(appService, props)
  }
}

const PageRoot: React.FC<{ element: React.ReactNode; props: PageProps }> = ({
  element,
  props
}) => {
  const pageService = cretePageService(appService, props)
  return <PageProvider value={pageService}>{element}</PageProvider>
}

export default PageRoot
