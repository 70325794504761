import React from 'react'
import AppProvider from '~/providers/AppProvider'
import AppService from '~/services/app.service'
import './default.css'

export const appService = new AppService()

const AppRoot: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  return <AppProvider value={appService}>{element}</AppProvider>
}

export default AppRoot
