import { PageProps } from 'gatsby'
import { BehaviorSubject } from 'rxjs'
import AppService from './app.service'
import type { PageContext, PostListPageContext, PostPageContext } from '~/types'

export class PageService {
  type: string
  urlPath: string
  parentUrl: string
  depth: number
  constructor(private readonly app: AppService, props: PageProps) {
    const pc: PageContext = props.pageContext as PageContext
    this.type = pc.type
    this.urlPath = pc.urlPath
    this.parentUrl = pc.parentUrl
    this.depth = pc.depth
    app.pageUrl$.next(this.urlPath)
    console.log('PageService constructor', pc)
  }
}

export class PostListService extends PageService {
  urlKey: string
  index: number
  numOfPosts: number
  constructor(app: AppService, props: PageProps) {
    super(app, props)
    const pc: PostPageContext = props.pageContext as PostPageContext
    this.urlKey = pc.urlKey
    this.index = pc.index
    this.numOfPosts = pc.numOfPosts
  }
}

export class PostService extends PageService {
  postPerPage: number
  numPages: number
  currentPage: number
  constructor(app: AppService, props: PageProps) {
    super(app, props)
    const pc: PostListPageContext = props.pageContext as PostListPageContext
    this.postPerPage = pc.limit
    this.numPages = pc.numPages
    this.currentPage = pc.currentPage
  }
}

export default PageService
