export const baseWidth = 860
export const maxWidth = 920
export const baseFontSize = 21
export const baseLineHeight = 1.8
export const headerWeight = 200
export const bodyWeight = 300
export const boldWeight = 600
export const scaleRatio = 1.7
export const multiplicity = 4
export const headerFontFamily = [
  // 'Adobe Clean',
  'Source Sans Pro',
  'Roboto',
  'Segoe UI',
  'Trebuchet MS',
  'Lucida Grande'
]
export const bodyFontFamily = ['Merriweather', 'Source Serif Pro', 'Georgia']
export const animationDuration = '.2s'
export const borderRadius = 0
export const colorsSteps = 15

export const black1 = 'black'
export const black2 = '#151515'
export const gold1 = '#FFD392'
export const gold2 = '#FFC34F'
export const text1 = '#FFD392'
export const text2 = '#FFE090'
export const text3 = '#6E6244'
export const text4 = '#C3BEAC'

export interface ColorTheme {
  background: string
  textColor: string
  primaryColor: string
}

export interface FontTheme {
  bodyFamily: string
  headerFamily: string
  bodyWeight: number
  boldWeight: number
  headerWeight: number
  bodySize: number
  fontProportion: number
}

export const defaultColorTheme = 'dark'

export const COLOR_THEMES = {
  light: {
    background: 'white',
    textColor: 'black',
    primaryColor: '#AC6E31'
  },
  dark: {
    background: 'black',
    textColor: 'white',
    primaryColor: '#FFE090'
  },
  sepia: {
    background: '#eadbcb',
    textColor: '#21170d',
    primaryColor: '#AC6E31'
  },
  grey: {
    background: '#cccccc',
    textColor: '#111111',
    primaryColor: '#AC6E31'
  }
}

export const defaultFontTheme = 'serif'

export const FONT_THEMES = {
  sansLight: {
    bodyFamily: 'Noto Sans',
    headerFamily: 'Noto Sans',
    bodyWeight: 200,
    boldWeight: 500,
    headerWeight: 400,
    bodySize: 18,
    fontProportion: 1.912
  },
  sans: {
    bodyFamily: 'Noto Sans',
    headerFamily: 'Noto Sans',
    bodyWeight: 300,
    boldWeight: 600,
    headerWeight: 400,
    bodySize: 18,
    fontProportion: 1.912
  },
  serifLight: {
    bodyFamily: 'Noto Serif',
    headerFamily: 'Noto Serif',
    bodyWeight: 200,
    boldWeight: 500,
    headerWeight: 400,
    bodySize: 18,
    fontProportion: 1.76
  },
  serif: {
    bodyFamily: 'Noto Serif',
    headerFamily: 'Noto Serif',
    bodyWeight: 300,
    boldWeight: 600,
    headerWeight: 400,
    bodySize: 18,
    fontProportion: 1.76
  }
}
